<template>
  <div>
    <div class="fd-img-wrap">
      <img style="width:100%" src="../assets/images/about-banner.png" alt="">
    </div>
    <div class="fd-about-content-wrap">
      <div class="fd-about-left-wrap">
        <div class="fd-about-left-item" :class="{'fd-checked': keyIndex === 0}" @click="adgyClick(0)">公司介绍</div>
        <div class="fd-about-left-item" :class="{'fd-checked': keyIndex === 1}" @click="adgyClick(1)">愿景与使命</div>
        <div class="fd-about-left-item" :class="{'fd-checked': keyIndex === 2}" @click="adgyClick(2)">公司荣誉</div>
        <div class="fd-about-left-item" :class="{'fd-checked': keyIndex === 3}" @click="adgyClick(3)">软件著作权</div>
        <div class="fd-about-left-item" :class="{'fd-checked': keyIndex === 4}" @click="adgyClick(4)">联系我们</div>
        <div class="fd-about-left-item1" :class="{'fd-yj-height': keyIndex === 1}"></div>
      </div>
      <div class="fd-about-right-wrap">
        <!-- 表示公司介绍部分 -->
        <div class="fd-gsjs-wrap" v-if="keyIndex === 0">
          <div class="fd-gsjs-right-l">
            <img src="../assets/images/dalou.png" alt="">
          </div>
          <div class="fd-gsjs-right-r">
            <div style="margin-bottom:25px">
              上海傲度金融信息服务有限公司是一家综合性金融数据应用服务供应商，金融科技（FinTech）是公司的核心定位。
            </div>
            <div style="margin-bottom:25px">
              公司致力于通过大数据和人工智能为海内外金融机构提供行业信息数据服务。
            </div>
            <div>
              客户涉及证券、基金、银行、信托、资管、第三方销售、私募等多个金融领域。
            </div>
          </div>
        </div>
        <!-- 表示愿景与使命部分 -->
        <div class="fd-yjsm-wrap" v-if="keyIndex === 1">
          <div class="fd-yjsm-item" :class="{'fd-yjcheck': yjIndex === 0}" @mouseenter="clickYjContent(0)" @mouseleave="cancleIndex">
            <div class="fd-img-style">
              <img :src="yjIndex === 0 ? require('../assets/images/qywh_normal.png') : require('../assets/images/qywh_checked.png')" alt="">
            </div>
            <div style="font-size:24px;margin-bottom:28px">企业文化</div>
            <div class="fd-wz-descirption">
              <div style="display:flex;justify-content: space-between;width:100%">
                <span>诚信</span>
                <span>创新</span>
                <span>专业</span>
              </div>
              <span style="margin-right:10px">责任</span>
              <span>分享</span>
            </div>
          </div>
          <div class="fd-yjsm-item fd-padding-002" :class="{'fd-yjcheck': yjIndex === 1}" @mouseenter="clickYjContent(1)" @mouseleave="cancleIndex">
            <div class="fd-img-style">
              <img :src="yjIndex === 1 ? require('../assets/images/qyyj_normal.png') : require('../assets/images/qyyj_checked.png')" alt="">
            </div>
            <div style="font-size:24px;margin-bottom:28px">企业愿景</div>
            <div class="fd-wz-descirption">
              成为国内最有价值的金融科技服务商
            </div>
          </div>
          <div class="fd-yjsm-item fd-padding-003" :class="{'fd-yjcheck': yjIndex === 2}" @mouseenter="clickYjContent(2)" @mouseleave="cancleIndex">
            <div class="fd-img-style">
               <img :src="yjIndex === 2 ? require('../assets/images/qysm_normal.png') : require('../assets/images/qysm_checked.png')" alt="">
            </div>
            <div style="font-size:24px;margin-bottom:28px">企业使命</div>
            <div class="fd-wz-descirption">
              让金融服务更简单
            </div>
          </div>
          <div class="fd-yjsm-item" :class="{'fd-yjcheck': yjIndex === 3}" @mouseenter="clickYjContent(3)" @mouseleave="cancleIndex">
            <div class="fd-img-style">
              <img :src="yjIndex === 3 ? require('../assets/images/qyln_normal.png') : require('../assets/images/qyln_checked.png')" alt="">
            </div>
            <div style="font-size:24px;margin-bottom:28px">企业理念</div>
            <div class="fd-wz-descirption">
              专注金融服务<br>
              专业服务金融
            </div>
          </div>
        </div>
        <!-- 表示公司荣誉部分 -->
        <div class="fd-gsry-wrap" v-if="keyIndex === 2">
          <div class="fd-left-price">
            <img src="../assets/images/授权书.png" alt="">
          </div>
          <div class="fd-right-describtion" style="width:664px">
            <div style="color:#666666; font-size: 28px;text-align:left;height:50px;line-height:50px;margin-bottom: 44px"><span style="border-bottom: 4px solid #026BA7;display:inline-block">香港</span>交易所授权</div>
            <div class="fd-line-name"></div>
            <div class="fd-r-item-wrap">
              <div class="fd-icon-dot"></div>
              <div style="font-size:20px;font-weight:400;color:rgba(102,102,102,1);line-height:36px;">香港交易所集团是全球最大金融市场营运机构之一;</div>
            </div>
            <div class="fd-r-item-wrap" style="align-items: baseline">
               <div class="fd-icon-dot"></div>
              <div  style="font-size:20px;font-weight:400;color:rgba(102,102,102,1);line-height:36px;">为股本证券、大宗商品、定息及货币等多种资产类别产品提供一 流的交易及结算服务;</div>
            </div>
            <div class="fd-r-item-wrap">
              <div class="fd-icon-dot"></div>
              <div  style="font-size:20px;font-weight:400;color:rgba(102,102,102,1);line-height:36px;">香港交易所是傲度信息在香港证券市场上的重要合作伙伴。</div>
            </div>
          </div>
        </div>
        <!-- 软件著作权部分 -->
        <div class="fd-rhzzq-wrap" v-if="keyIndex === 3">
          <img src="../assets/images/price.png" alt="">
        </div>
        <!-- 联系我们部分 -->
        <div class="fd-lxwm-wrap" v-if="keyIndex === 4">
          <div class="fd-top-wrap-lxwm">
            <h2 style="margin-bottom:32px;font-size:24px">上海傲度金融信息服务有限公司</h2>
            <div style="margin-bottom:10px;font-size:20px;color: #666">地址：上海市浦东新区银城路88号中国人寿金融中心7F</div>
            <!-- <div style="margin-bottom:10px;font-size:20px;color: #666;text-indent: 62px">深圳市罗湖区深南东路5002号地王大厦55F</div> -->
            <div style="margin-bottom:10px;font-size:20px;color: #666">邮编：200120</div>
            <div style="margin-bottom:10px;font-size:20px;color: #666">电话：021-50655060</div>
            <div style="margin-bottom:10px;font-size:20px;color: #666">Web: www.alldobetter.com</div>
          </div>
          <div class="fd-dt-area" id="allMap" ref="allMap">
            <!-- <img src="../assets/images/dt.png" alt=""> -->
            <baidu-map class="map" :center="{lng: 121.517556, lat: 31.246425}" :scroll-wheel-zoom="true" :zoom="18">
              <!-- 缩放控件 -->
              <!-- <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation> -->
              <bm-marker :position="{lng: 121.517556, lat: 31.246425}" :dragging="false" animation="BMAP_ANIMATION_BOUNCE">
                <bm-label content="上海傲度金融信息服务有限公司" :labelStyle="{color: 'black', fontSize : '24px'}" :offset="{width: -35, height: 30}"/>
              </bm-marker>
              <!-- <bm-marker :position="{lng: 114.117114, lat: 22.549014}" :dragging="false" animation="BMAP_ANIMATION_BOUNCE">
                <bm-label content="上海傲度信息科技有限公司深圳分公司" :labelStyle="{color: 'black', fontSize : '24px'}" :offset="{width: -35, height: 30}"/>
              </bm-marker> -->
            </baidu-map>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import BMap from 'BaiduMap'
export default {
  name: 'about',
  data () {
    return {
      keyIndex: 0, // 表示点击下标
      yjIndex: -1
    }
  },
  methods: {
    initMap () {
      // 创建Map实例
      var map = new BMap.Map("allMap")
      let point = new BMap.Point(121.517556, 31.246425)
      // 初始化地图,设置中心点坐标和地图级别
      map.centerAndZoom(point, 12)
      // let point1 = new BMap.Point(114.117114, 22.549014)
      // 初始化地图,设置中心点坐标和地图级别
      // map.centerAndZoom(point1, 17)
      map.enableScrollWheelZoom(true)
      map.enableDoubleClickZoom(true)
      //添加地图类型控件
      // map.addControl(new BMap.MapTypeControl({
      //   mapTypes:[BMAP_NORMAL_MAP, BMAP_HYBRID_MAP]
      // }))
      // // 设置地图显示的城市 此项是必须设置的
      // map.setCurrentCity("上海傲度信息科技有限公司")
      // //开启鼠标滚轮缩放
      // map.enableScrollWheelZoom(true)
      /* eslint-enable */
    },
    clickYjContent (index) {
      this.yjIndex = -1
    },
    //取消
    cancleIndex () {
      this.yjIndex = -1
    },
    // 傲度关于左侧导航点击
    adgyClick (index) {
      this.keyIndex = index
    }
  },
  mounted () {
    // this.initMap()
  }
}
</script>
<style lang="stylus" scoped>
.fd-about-content-wrap
  width 1200px
  margin 0 auto 
  padding 80px 0
  display flex
  justify-content space-between
  // align-items center
  .fd-about-left-wrap
    width 175px
    .fd-about-left-item
      width 175px
      height 50px
      color #026BA7
      font-size 20px
      line-height 50px
      text-align center
      cursor pointer
      background-color rgba(246,246,246,1)
      margin-bottom 10px
    .fd-about-left-item1
      width 175px
      height calc(100% - 300px)
      background-color rgba(246,246,246,1)
  .fd-about-right-wrap
    width 995px
    .fd-gsjs-wrap
      display flex
      justify-content space-between
      .fd-gsjs-right-l
        width 367px
        height 340px
      .fd-gsjs-right-r
        width 560px
        height 340px
        padding 37px 0
        font-size 20px
        color #666
        line-height 36px
        text-align left
    .fd-yjsm-wrap
      width 990px
      display flex
      justify-content space-between
      .fd-yjsm-item
        width 225px
        height 290px
        padding-top 51px
        padding-bottom 39px
        padding-left 53px
        padding-right 53px
        text-align center
        box-shadow 0px 0px 13px 0px rgba(130,130,130,0.1)
        color #026BA7
        cursor pointer
.fd-checked
  background-color rgba(2,107,167,1) !important
  color white !important
.fd-yjcheck
  background url('../assets/images/yjbj.png') no-repeat !important
  color white !important
.fd-yj-height
  height 0px !important
.fd-img-style
  height 49px
  margin: 0 auto 36px;
  img
    height 100%
    vertical-align middle
.fd-wz-descirption
  display flex
  font-size 18px
  flex-wrap wrap
  justify-content center
.fd-color-white
  color white !important
.fd-padding-002
  padding-left 31px !important
  padding-right 31px !important
.fd-padding-003
  padding-left 40px !important
  padding-right 40px !important
.fd-gsry-wrap
  width 990px
  display flex
  justify-content space-between
  height 447px
  .fd-right-describtion
    padding-top 50px
.fd-r-item-wrap
  display flex
  align-items center
  margin-bottom 45px
  // justify-content space-between
  .fd-icon-dot
    width 12px
    height 12px
    margin-right 20px
    border 2px solid rgba(2,107,167,1)
    transform rotate(45deg)
.fd-rhzzq-wrap
  width 998px
.fd-lxwm-wrap
  width 927px
#allMap, .map
  width 927px
  height 530px
.fd-about-left-item:hover
  color #76c3ff !important
  text-decoration underline
</style>

